<template>
  <v-row no-gutters>
    <v-col class="d-flex flex-column height-100">
      <!--Header-->
      <v-row class="bb-gray px-7 py-3 ma-0 flex-grow-0 d-flex align-center justify-space-between" style="max-height: 70px; min-height: 70px">
      <span class="fz-16 font-weight-bold text-black" style="margin-left: 2px">
        Order History
      </span>
        <v-icon
          color="#010820"
          class="rounded-circle chevron-right"
          @click="$emit('closeOrdersInfo')"
        >
          mdi-chevron-right
        </v-icon>
      </v-row>
      <!--Cards of orders -->
      <v-row class="ma-0 flex-grow-1 scroll">
        <v-col style="padding: 30px !important;">
          <v-card
            v-for="order in orders"
            :key="order.id"
            outlined
            class="rounded-4 mb-5 pointer"
            @click.native="$emit('getOrder', order.id)"
          >
            <v-col class="py-4 px-5">
              <v-row class="ma-0 mb-2">
                <v-tooltip bottom max-width="600">
                  <template v-slot:activator="{ on, attrs }">
                    <v-chip
                      v-bind="attrs"
                      v-on="order.status.value === 'CANCELED' ? on : null"
                      class="ma-0 mr-1 my-auto white--text"
                      x-small
                      style="height: 18px"
                      :style="color(order.status.value)"
                      label
                    >
                      {{ order.status.label }}
                    </v-chip>
                  </template>
                  <v-col class="d-flex flex-column flex-wrap flex-grow-0">
                    <div
                      v-for="(reason, index) of order.itemsCancellationReasons"
                      :key="index"
                      class="width-100"
                      style="word-break: break-all"
                    >
                      <span>
                        {{ reason }}
                      </span>
                    </div>
                  </v-col>
                </v-tooltip>
                <v-chip
                  class="ma-0 my-auto white--text"
                  x-small
                  color="#EB5757"
                  style="height: 18px"
                  label
                  v-if="!order.paymentDone"
                >
                  unpaid
                </v-chip>
              </v-row>
              <v-row class="ma-0 fz-14 mb-2">
                <span class="text-gray">Project:&nbsp;</span><b class="text-black font-weight-medium"> {{ order.name }}</b>
              </v-row>
              <template v-if="type !== 'designers'">
                <v-row class="ma-0 fz-14 mb-2">
                  <span class="text-gray">Date of creation:&nbsp;</span><b class="text-black font-weight-medium"> {{ order.createdAt ? formatDate(order.createdAt) : '' }}</b>
                </v-row>
                <v-row class="ma-0 fz-14 mb-2">
                  <span class="text-gray">Update date:&nbsp;</span><b class="text-black font-weight-medium"> {{ order.updatedAt ? formatDate(order.updatedAt) : '' }}</b>
                </v-row>
                <v-row class="ma-0 fz-14 mb-2">
                <span class="text-gray">
                  Expiration date:&nbsp;
                </span>
                  <b class="text-black font-weight-medium mr-2" :class="{'color-primary': order.dateEndPrediction !== order.dateEnd}">{{ formatDate(order.dateEnd) }}</b>
                  <small class="text-black fz-10">{{ order.dateEndPrediction !== order.dateEnd ? formatDate(order.dateEndPrediction) : '' }}</small>
                </v-row>
              </template>
              <template v-else>
                <v-row class="ma-0 fz-14 mb-2">
                  <span class="text-gray">Completed: <b class="text-black font-weight-medium">{{ order.dateEnd ? formatDate(order.dateEnd) : '' }}</b></span>
                </v-row>
              </template>
              <v-row v-if="type === 'leads' || type === 'designers'" class="ma-0 fz-14 mb-2">
                <span class="text-gray">Manager:&nbsp;</span><b class="text-black font-weight-medium">{{ order.manager.businessName }}</b>
              </v-row>
              <v-row v-if="type === 'company' || type === 'designers'" class="ma-0 fz-14 mb-2">
                <span class="text-gray">Client: <b class="text-black font-weight-medium">{{ order.client ? order.client.name : '' }}</b></span>
              </v-row>
              <v-row v-if="type !== 'designers'" class="ma-0 fz-14">
                <span class="text-gray">Final cost:&nbsp;</span><b class="text-black font-weight-medium">{{ order.currencySymbol !== '₽' ? order.currencySymbol : '' }}{{ +order.totalCost | numberFormat }}{{ order.currencySymbol === '₽' ? order.currencySymbol : '' }}</b>
              </v-row>
              <v-row v-if="type === 'designers'" class="ma-0 fz-14">
                <span class="text-gray">Cost:&nbsp;</span><b class="text-black font-weight-medium">{{ order.currencySymbol !== '₽' ? order.currencySymbol : '' }}{{ +order.cost | numberFormat }}{{ order.currencySymbol === '₽' ? order.currencySymbol : '' }}</b>
              </v-row>
            </v-col>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
    <!--Others-->
    <div class="preloader" v-if="loading">
      <v-progress-circular
        indeterminate
        color="primary"
      />
    </div>
  </v-row>
</template>

<script>
import { Locale } from 'v-calendar'
import numberFormat from '@/helpers/numberFormat'
import { statusColor } from '@/helpers/staticColors'
const locale = new Locale()

export default {
  props: {
    orders: {
      type: Array,
      default: () => []
    },
    loading: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      required: true,
      validator(val) {
        return ['leads', 'company', 'designers'].includes(val)
      }
    }
  },
  filters: {
    numberFormat
  },
  methods: {
    formatDate (date) {
      return locale.format(date, 'DD.MM.YYYY')
    },
    color (value) {
      return statusColor(value)
    }
  }
}
</script>

<style scoped>

</style>
